import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth-guard.service';
import { NoAuthGuard } from 'src/app/core/guards/no-auth-guard.service';
import { RedirectGuard } from 'src/app/core/guards/redirect-guard.service';

const NO_AUTH_REDIRECTS = [
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

const routes: Routes = [
  // Both
  {
    path: 'password-reset',
    loadChildren: () => import('./modules/password-reset/password-reset.module').then(module => module.PasswordResetModule),
  },
  {
    path: 'registration-success',
    loadChildren: () =>
      import('./modules/registration-success/registration-success.module').then(module => module.RegistrationSuccessModule),
  },

  // No-Auth
  {
    path: 'login',
    canActivate: [RedirectGuard],
    component: RedirectGuard, // Any random thing here. This will redirect to the Login Page external site
    data: {
      externalUrl: `${window.location.origin}/my-accounts/login`,
    },
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/no-auth/registration/registration.module').then(module => module.RegistrationModule),
    canActivate: [NoAuthGuard],
  },

  // Auth
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule),
    canActivate: [AuthGuard],
  },

  ...NO_AUTH_REDIRECTS,
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountsRoutingModule {}
