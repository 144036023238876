export interface DataLayerEvent {
  event: string;
  [key: string]: string | number | boolean;
}

export enum DataLayerProduct {
  VirtualsScheduled = 'virtuals-scheduled',
  VirtualsInstant = 'virtuals-instant',
  SportsBookLive = 'sportsbook-live',
  SportsBookPrematch = 'sportsbook-prematch',
  SportsBookMixed = 'sportsbook-mixed',
}

export enum BetslipActions {
  AddToBetslip = 'add_to_betslip',
  BetslipOpen = 'betslip_open',
  BetslipError = 'betslip_error',
  BetSubmitted = 'bet_submitted',
  BetSuccess = 'bet_success',
  BetFailure = 'bet_failure',
  BookBetSubmitted = 'book_bet_submitted',
  BookBetSuccess = 'book_bet_success',
  BookBetFailure = 'book_bet_failure',
}

export interface BetslipActionsDetails {
  event: BetslipActions;
  product: DataLayerProduct;
  userId?: number;
  currency?: string;
  betStake?: number;
  betType?: string;
  errorMessage?: string;
  couponId?: string;
  selectionId?: number;
  location?: string;
  isFreeBet?: boolean;
  selections?: number;
  category?: string;
  subCategory?: string;
}

export enum DepositActions {
  DepositMyAccountClicked = 'Deposit_MyAccount_Clicked',
  DepositHomeNavBarClicked = 'Deposit_Home_Nav_Bar_Clicked',
  DepositTutorialClicked = 'Deposit_Tutorial_Clicked',
  DepositHelpPageClicked = 'Deposit_HelpPage_Clicked',
  DepositTransactionHistoryClicked = 'Deposit_Transaction_History_Clicked',
}

export enum AdjustEvents {
  Registration = 'thowgu',
  BetSuccess = 'vp9he2',
  FirstBetSuccess = 'ry33vo',
  DepositSuccess = '4y77z3',
}

export enum CashoutActions {
  CashoutInitiated = 'cashout_initiated',
  CashoutSubmitted = 'cashout_submitted',
  CashoutSuccess = 'cashout_success',
  CashoutFailed = 'cashout_failed',
}

export enum FirebaseEventSectionName {
  Login = 'Login',
  Betslip = 'Betslip',
}

export enum FirebaseEventComponentName {
  Button = 'button',
  Form = 'form',
  Link = 'link',
  Input = 'input',
  Service = 'service',
  Select = 'select',
  Checkbox = 'checkbox',
}

export enum FirebaseEventTrigger {
  Click = 'click',
  ScreenView = 'screen_view',
  Submit = 'submit',
  Login = 'login',
  Logout = 'logout',
  Request = 'request',
  Response = 'response',
  Error = 'error',
}

export enum FirebaseEventFrom {
  Web = 'web',
}

export const FirebaseEvent = {
  BetSuccess: {
    command: 'logEvent',
    name: 'fa_betslip_betSuccess_response',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Betslip,
      component_name: FirebaseEventComponentName.Service,
      trigger_by: FirebaseEventTrigger.Response,
      from: FirebaseEventFrom.Web,
    },
  },
  FTBSuccess: {
    command: 'logEvent',
    name: 'fa_betslip_ftpSuccess_response',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Betslip,
      component_name: FirebaseEventComponentName.Service,
      trigger_by: FirebaseEventTrigger.Response,
      from: FirebaseEventFrom.Web,
    },
  },
  BetSubmittedClick: {
    command: 'logEvent',
    name: 'fa_betslip_betSubmitted_click',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Betslip,
      component_name: FirebaseEventComponentName.Button,
      trigger_by: FirebaseEventTrigger.Click,
      from: FirebaseEventFrom.Web,
    },
  },
  CashoutClick: {
    command: 'logEvent',
    name: 'fa_myBets_cashoutInitiated_click',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Betslip,
      component_name: FirebaseEventComponentName.Button,
      trigger_by: FirebaseEventTrigger.Click,
      from: FirebaseEventFrom.Web,
    },
  },
  CashoutConfirmClick: {
    command: 'logEvent',
    name: 'fa_myBets_cashoutSubmitted_click',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Betslip,
      component_name: FirebaseEventComponentName.Button,
      trigger_by: FirebaseEventTrigger.Click,
      from: FirebaseEventFrom.Web,
    },
  },
  CashoutSuccess: {
    command: 'logEvent',
    name: 'fa_myBets_cashoutSuccess_response',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Betslip,
      component_name: FirebaseEventComponentName.Service,
      trigger_by: FirebaseEventTrigger.Response,
      from: FirebaseEventFrom.Web,
    },
  },
  LoginFillUsername: {
    command: 'logEvent',
    name: 'fa_login_username_submit',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Login,
      component_name: FirebaseEventComponentName.Input,
      trigger_by: FirebaseEventTrigger.Submit,
      from: FirebaseEventFrom.Web,
    },
  },
  LoginFillPassword: {
    command: 'logEvent',
    name: 'fa_login_password_submit',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Login,
      component_name: FirebaseEventComponentName.Input,
      trigger_by: FirebaseEventTrigger.Submit,
      from: FirebaseEventFrom.Web,
    },
  },
  LoginClickCancel: {
    command: 'logEvent',
    name: 'fa_login_cancel_click',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Login,
      component_name: FirebaseEventComponentName.Button,
      trigger_by: FirebaseEventTrigger.Click,
      from: FirebaseEventFrom.Web,
    },
  },
  LoginClickBackgroundCancel: {
    command: 'logEvent',
    name: 'fa_login_backgroundCancel_click',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Login,
      component_name: FirebaseEventComponentName.Button,
      trigger_by: FirebaseEventTrigger.Click,
      from: FirebaseEventFrom.Web,
    },
  },
  LoginClickLogin: {
    command: 'logEvent',
    name: 'fa_login_click',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Login,
      component_name: FirebaseEventComponentName.Button,
      trigger_by: FirebaseEventTrigger.Click,
      from: FirebaseEventFrom.Web,
    },
  },
  LoginClickForgotPassword: {
    command: 'logEvent',
    name: 'fa_login_forgotPassword_click',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Login,
      component_name: FirebaseEventComponentName.Button,
      trigger_by: FirebaseEventTrigger.Click,
      from: FirebaseEventFrom.Web,
    },
  },
  LoginClickRegister: {
    command: 'logEvent',
    name: 'fa_login_register_click',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Login,
      component_name: FirebaseEventComponentName.Button,
      trigger_by: FirebaseEventTrigger.Click,
      from: FirebaseEventFrom.Web,
    },
  },
  LoginResponseSuccessful: {
    command: 'logEvent',
    name: 'fa_login_success_response',
    params: {
      site_ux: 'Native',
      country_code: 'NG',
      section_name: FirebaseEventSectionName.Login,
      component_name: FirebaseEventComponentName.Service,
      trigger_by: FirebaseEventTrigger.Response,
      from: FirebaseEventFrom.Web,
    },
  },
} as const;
