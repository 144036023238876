import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { BetCouponOdd } from 'clientside-coupon';
import { BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { catchError, filter, finalize, first, map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { DynamicScriptLoaderService } from 'src/app/core/services/dynamic-script-loader.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { RegistrationStore } from 'src/app/core/state/registration/registration.store';
import { APIType } from 'src/app/shared/models/api.model';
import { ApplicationUIState, CmsModel, MenuMethodCalls } from 'src/app/shared/models/application.model';
import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { ProductType } from 'src/app/shared/models/product.model';
import { BetslipActions, DataLayerProduct } from 'src/app/shared/models/datalayer.model';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { VirtualsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-virtuals.service';
import { SportsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-sports.service';
import { JackpotBetsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-jackpotbets.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  menuMethodCalls: MenuMethodCalls = {
    loadLiveChat: () => {
      switch (this.appConfigService.get('liveChat').liveChatIntegration) {
        case 'salesforce':
          this.openSalesForceChat();
          break;
        case 'sfk':
          this.applicationStore.updateUI({ loadingSFKChat: true });
          break;
        case 'zoho':
          this.dynamicScriptLoaderService.loadZohoLiveChat();
          break;
        default:
          break;
      }
    },
    openBetSlip: () => {
      this.showCoupon();
    },
  };

  enableSlideUps = false;
  private isFirstLoad = true;
  private _enableQuickCouponForPrematch: boolean = false;
  private _enableQuickCouponForLive: boolean = false;
  private readonly isQuickCouponEnabledForPage$ = new BehaviorSubject(false);
  private readonly marketToParse1X2AsTeamNames = this.appConfigService.get('sports').coupon.marketToParse1X2AsTeamNames;

  constructor(
    private readonly router: Router,
    private readonly accountQuery: AccountQuery,
    private readonly couponQuery: CouponQuery,
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly applicationStore: ApplicationStore,
    private readonly registrationStore: RegistrationStore,
    private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
    private readonly dataLayerService: DataLayerService,
    private readonly loadingService: LoadingService,
    private readonly virtualsMyBetsService: VirtualsMyBetsService,
    private readonly sportsMyBetsService: SportsMyBetsService,
    private readonly jackpotBetsMyBetsService: JackpotBetsMyBetsService
  ) {}

  initialize(): void {
    this.applicationStore.updateBrandId(this.appConfigService.get('brandId'));
    this.enableSlideUps = this.appConfigService.get('enableSlideUps');
    this.registrationStore.updateRegistrationType(this.appConfigService.get('registration').registrationType.toLowerCase());
    this._enableQuickCouponForPrematch = this.appConfigService.get('enableQuickCouponForPrematch');
    this._enableQuickCouponForLive = this.appConfigService.get('enableQuickCouponForLive');

    // Handle isQuickCouponEnabledForPage$
    this.router.events
      .pipe(
        filter((event: NavigationEnd) => !!event.url),
        map(
          (event: NavigationEnd) =>
            (event.url.includes('/live') && this._enableQuickCouponForLive) ||
            (!(this.router.url.includes('/live') || this.router.url.includes('/account')) && this._enableQuickCouponForPrematch)
        )
      )
      .subscribe(isQuickCouponEnabledForPage => this.isQuickCouponEnabledForPage$.next(isQuickCouponEnabledForPage));

    combineLatest([this.router.events, this.applicationQuery.showQuickCoupon$, this.isQuickCouponEnabledForPage$])
      .pipe(
        filter(([_event, showingQuickCoupon, isQuickCouponEnabledForPage]) => showingQuickCoupon && !isQuickCouponEnabledForPage),
        tap(() => {
          // Hide quick coupon for pages that should not have quick coupon
          this.updateUI({
            showQuickCoupon: false,
          });
        })
      )
      .subscribe();

    // Get open my-bets count
    setTimeout(() => {
      combineLatest([this.accountQuery.isAuthenticated$, this.applicationQuery.activeProduct$])
        .pipe(
          filter(([isAuth]) => isAuth && !this.applicationQuery.inMyBets),
          tap(() => {
            if (this.applicationQuery.isVirtuals) {
              this.virtualsMyBetsService.getMyBetsCount();
            } else if (this.applicationQuery.isJackpotBets) {
              this.jackpotBetsMyBetsService.getMyBetsCount();
            } else if (this.applicationQuery.isSportsBook) {
              this.sportsMyBetsService.getMyBetsCount();
            }
          })
        )
        .subscribe();
    }, 0);
  }

  updateUI(ui: Partial<ApplicationUIState>): void {
    this.applicationStore.updateUI(ui);
  }

  updateActiveUrl(activeUrl: string[]): void {
    this.applicationStore.updateActiveUrl(activeUrl);
  }

  updateCms(cmsCacheTTL: Partial<CmsModel>): void {
    this.applicationStore.updateCms(cmsCacheTTL);
  }

  closeSlideUp(type: string): void {
    this.applicationStore.closeSlideUp(type);
  }

  closeAnySlideUps(): void {
    this.applicationStore.closeAnySlideUps();
  }

  showSlideUp(): void {
    if (this.accountQuery.isAuthenticated) {
      this.showAccountMenu();
    } else {
      this.showLogin();
    }
  }

  showAccountMenu(): void {
    if (this.router.url === '/account') {
      this.closeAnySlideUps();
      return;
    }

    let updateUI: ApplicationUIState;
    if (this.applicationQuery.showAccountMenu) {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
    } else {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showAccountMenu: true, landingMenuExpanded: false });
    }
    this.updateUI(updateUI);
  }

  showMenu(): void {
    let updateUI: ApplicationUIState;
    if (this.applicationQuery.showMenu) {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
    } else {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showMenu: true, landingMenuExpanded: false });
    }
    this.updateUI(updateUI);
  }

  async loadScriptAsync(src: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  async loadCSSAsync(url: string) {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      link.onload = resolve;
      link.onerror = reject;
      document.body.appendChild(link);
    });
  }

  async openSalesForceChat() {
    const initESW = async (gslbBaseURL: string | null) => {
      let intervalId = null;
      intervalId = setInterval(hideInputOnChatInit, 500);
      function hideInputOnChatInit() {
        window.embedded_svc.menu.addEventHandler('onChatTransferSuccessful', handleChatTransferSuccessful);
        const textBox = document.getElementsByClassName('chasitorInputWrapper');
        if (textBox.length !== 0) {
          clearInterval(intervalId);
          (textBox[0] as HTMLElement).style.display = 'none';
        }
      }

      await window.embedded_svc.menu.init(
        'https://betking.my.salesforce.com',
        'https://d.la1-c2-lo2.salesforceliveagent.com/chat',
        gslbBaseURL,
        '00D4J000000CuuI',
        'Contact_Us',
        {
          pageName: 'customSnippetSettings',
        }
      );
    };

    function handleChatTransferSuccessful() {
      const textBox = document.getElementsByClassName('chasitorInputWrapper');
      (textBox[0] as HTMLElement).style.display = 'block';
    }

    function liveChatOnDestroy(): void {
      const helpButton = document.querySelector<HTMLElement>('#esw-fab');
      if (helpButton) {
        helpButton.style.visibility = 'hidden';
      }
    }

    this.loadingService.addClass('new');
    this.loadingService.enqueueLoader();
    this.loadingService.updateFullscreen(true);
    if (!window.embedded_svc || !window.embedded_svc.menu) {
      await this.loadScriptAsync('https://betking.my.salesforce.com/embeddedservice/menu/fab.min.js');
      await this.loadCSSAsync('https://betkingsms.secure.force.com/resource/ChatSnipetCSS');
      await initESW(null);
    } else {
      await initESW('https://service.force.com');
    }

    const checkOpenChannelMenu = () => {
      const helpButton = document.querySelector<HTMLElement>('#esw-fab');
      const embeddedServiceElement = document.querySelector('#embedded-service');

      if (embeddedServiceElement && helpButton && window.embedded_svc.menu && window.embedded_svc.menu.openChannelMenu) {
        helpButton.style.visibility = 'visible';
        window.embedded_svc.menu.openChannelMenu();

        const closeButton = document.querySelector<HTMLElement>('#esw-menu-opened-fab-icon');
        if (closeButton) {
          closeButton.addEventListener('click', () => liveChatOnDestroy());
        }

        window.embedded_svc.menu.addEventHandler('afterDestroy', () => liveChatOnDestroy());
        this.toggleDropdownOverlay();
        this.loadingService.removeClass('new');
        clearInterval(checkInterval);
      }
    };

    // Periodically check if openChannelMenu is available
    const checkInterval = setInterval(checkOpenChannelMenu, 100);
  }

  showCoupon(): void {
    if (this.enableSlideUps) {
      let updateUI: ApplicationUIState;
      if (this.applicationQuery.showCoupon) {
        updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
      } else {
        updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showCoupon: true, landingMenuExpanded: false });
      }
      this.updateUI(updateUI);
    } else {
      this.router.navigate(['/coupon'], {
        queryParamsHandling: 'preserve',
      });
    }
  }

  showVirtualsCoupon(): void {
    this.router.navigate(['/virtual/coupon'], {
      replaceUrl: true,
    });
  }

  showQuickCoupon(showQuickCoupon: boolean, couponData?: BetCouponOdd): void {
    if (this.isQuickCouponEnabledForPage$.value) {
      let updateUI: ApplicationUIState;
      if (showQuickCoupon) {
        this.addEventToDataLayer(couponData);
        updateUI = new ApplicationUIState({ showQuickCoupon: true });
      } else {
        updateUI = new ApplicationUIState({ showQuickCoupon: false });
      }
      this.updateUI(updateUI);
    }
  }

  showMyBets(): void {
    if (!this.accountQuery.isAuthenticated) {
      return;
    }

    let updateUI: ApplicationUIState;
    if (this.applicationQuery.showMyBets) {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
    } else {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showMyBets: true, landingMenuExpanded: false });
    }
    this.updateUI(updateUI);
  }

  parseSelectionName(selectionName, homeTeam, awayTeam, marketTypeId?): string {
    if (!(marketTypeId && this.marketToParse1X2AsTeamNames.includes(marketTypeId))) {
      return selectionName;
    }

    switch (selectionName.toLowerCase().trim()) {
      case '1':
        return homeTeam ? homeTeam : selectionName;
      case 'x':
        return $localize`Draw`;
      case '2':
        return awayTeam ? awayTeam : selectionName;
      default:
        return selectionName;
    }
  }

  showLogin(): void {
    if (this.router.url === '/account/login') {
      this.closeAnySlideUps();
      return;
    }

    if (!this.appConfigService.get('enableSlideUps')) {
      this.navigateTo('/account/login');
    } else {
      let updateUI: ApplicationUIState;
      if (this.applicationQuery.showLogin) {
        updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
      } else {
        updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showLogin: true, landingMenuExpanded: false });
      }
      this.updateUI(updateUI);
    }
  }

  toggleDropdownOverlay(): void {
    const updateUI = new ApplicationUIState({
      ...this.applicationQuery.slideUpResetObject,
      showDropdownOverlay: !this.applicationQuery.showDropdownOverlay,
      landingMenuExpanded: false,
    });
    this.updateUI(updateUI);
  }

  navigateTo(link: string): void {
    if (!link) {
      return;
    }
    if (link === 'desktop') {
      window.open(this.appConfigService.get('desktopUrl'), '_blank');
    } else {
      this.closeAnySlideUps();
      this.router.navigate([link]);
    }
  }

  getSidebarLinks(): void {
    this.applicationStore.updateUI({ loadingQuicklinks: true });
    this.apiService
      .get(APIType.CMS, 'SiteRoot/GetSidebarQuicklinks')
      .pipe(
        catchError(err => {
          this.getMenuItemsFromConfig();
          return throwError(err);
        }),
        tap(links => {
          this.updateCms({ lastSidebarQuicklinksUpdate: this.getEpochTime() });

          if (links && links.length > 0) {
            this.applicationStore.updateSidebarQuickLinks(
              Capacitor.isNativePlatform() ? links.filter(x => x.linkURL !== '/download-app') : links
            );
          }
        }),
        finalize(() => {
          this.applicationStore.updateUI({ loadingQuicklinks: false });
        })
      )
      .subscribe();
  }

  getEpochTime(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  getEpochTimeDifference(epochTime: number): number {
    // If there was not any api call yet we interpret it that it happened Infinite time ago (to be able to compare values)
    return isNaN(epochTime) ? Infinity : this.getEpochTime() - epochTime;
  }

  getSideMenuTabs(): void {
    this.apiService
      .get(APIType.CMS, '/SiteRoot/GetSiteMenuTabs')
      .pipe(first())
      .subscribe(data => {
        if (data !== undefined && data.length > 0) {
          this.applicationStore.updateSideBarMenu(data);
        }
      });
  }

  resetBannerUpdateCaches(): void {
    this.updateCms({
      lastPaymentsBannerUpdate: undefined,
      lastSportsBannerUpdate: undefined,
    });
  }

  hideNavbar(): void {
    this.applicationStore.updateUI({
      showNavbar: false,
    });
  }

  showNavbar(): void {
    this.applicationStore.updateUI({
      showNavbar: true,
    });
  }

  /**
   * Active product dependant navigation to home page.
   */
  navigateHome(): void {
    switch (this.applicationQuery.activeProduct) {
      case ProductType.Virtuals:
        this.navigateTo('/virtual');
        break;
      case ProductType.JackpotBets:
        this.navigateTo('/jackpot-bets');
        break;
      case ProductType.SportsBook:
      default:
        this.navigateTo('/');
        break;
    }
  }

  loginRedirect(urlAfterLogin: string = document.location.pathname): void {
    window.location.href = `${window.location.origin}/my-accounts/login?urlAfterLogin=${urlAfterLogin}`;
  }

  virtualsLobbyRedirect(): void {
    this.router.navigate(['/virtual']);
  }

  private getMenuItemsFromConfig(): void {
    this.applicationStore.updateSidebarQuickLinks(
      this.appConfigService.get('menuDefaults').sidebarQuicklinks.map(mi => new MenuItemModel(mi))
    );
  }

  private addEventToDataLayer(couponData): void {
    this.dataLayerService.createDataLayerEvent({
      event: BetslipActions.BetslipOpen,
      user_id: this.accountQuery.userData?.id,
      product: DataLayerProduct.SportsBookLive,
      category: couponData.SportName,
      sub_category: couponData.TournamentName,
      allow_odds_change: this.couponQuery.couponSettings?.allowOddChanges,
      show_competition_name: this.couponQuery.couponSettings?.allowCompetitionGrouping,
      remember_last_stake: this.couponQuery.defaultCouponStake?.allowSaveDefault,
    });
  }

  setIsFirstLoad(value: boolean): void {
    this.isFirstLoad = value;
  }

  getIsFirstLoad(): boolean {
    return this.isFirstLoad;
  }
}
