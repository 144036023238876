import { Injectable } from '@angular/core';
import MD5 from 'crypto-js/md5';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { BetslipActionsDetails, DataLayerEvent } from 'src/app/shared/models/datalayer.model';
import { FirebaseEventType } from 'src/app/shared/models/firebase-event-types';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(private readonly appConfig: AppConfigService) {}

  createDataLayerEvent(event: DataLayerEvent): void {
    if (this.appConfig.get('enableDataLayer')) {
      const global = window as any;
      global.dataLayer = global.dataLayer || [];
      global.dataLayer.push(event);
    }
  }

  async getAdjustIds() {
    const Adjust = window.Adjust;

    if (Adjust && Adjust.getAdid) {
      const adjustId: string =
        Adjust.getAdid() ||
        (await new Promise(resolve => {
          Adjust.getAdid(id => resolve(id));
        }));

      if (Adjust.getGoogleAdId) {
        const gpsAdid: string = await new Promise(resolve => {
          Adjust.getGoogleAdId(id => resolve(id));
        });
        return { gpsAdid, adjustId };
      }

      if (Adjust.getIdfa) {
        const idfa: string = await new Promise(resolve => {
          Adjust.getIdfa(id => resolve(id));
        });
        return { adjustId, idfa };
      }
    }
  }

  createAdjustDataLayerEvent(eventToken, parameters: Record<string, string | number | boolean>): void {
    const global = window as any;
    const Adjust = global.Adjust;
    const AdjustEvent = global.AdjustEvent;
    if (Adjust && AdjustEvent) {
      const adjustEvent = new AdjustEvent(eventToken);

      if ('bet_stake' in parameters && 'currency' in parameters) {
        const betStake = parameters['bet_stake'];
        const currency = parameters['currency'];
        adjustEvent.setRevenue(betStake, currency);
        // Remove 'bet_stake' from parameters as it is already set as revenue
        delete parameters['bet_stake'];
        delete parameters['currency'];
      }

      Object.entries(parameters).forEach(([key, value]) => {
        adjustEvent.addPartnerParameter(key, value);
      });
      Adjust.trackEvent(adjustEvent);
    }
  }

  createOptimoveEvent(event: string, key: string, value: string): void {
    if (this.appConfig.get('enableDataLayer')) {
      const global = window as any;
      global.dataLayer = global.dataLayer || [];
      const object = {
        event: event,
      };
      object[key] = value;
      global.dataLayer.push(object);
    }
  }

  toMD5Hash(data: any): string {
    return MD5(data).toString();
  }

  /**
   * Get the Site UX value to be used in datalayer events
   * as per UP-186
   */
  dataLayerSiteUX(): string {
    const devREG = new RegExp('(.azurewebsites.net)|(localhost)');

    return devREG.exec(window.location.hostname) ? 'DEV' : 'M';
  }

  addBetslipEventToDataLayer(details: BetslipActionsDetails): void {
    const event = {
      event: details.event,
      product: details.product,
    };

    if (details.userId) {
      event['user_id'] = details.userId;
    }

    if (details.currency) {
      event['currency'] = details.currency;
    }

    if (details.errorMessage) {
      event['error_message'] = details.errorMessage;
    }

    if (details.betStake) {
      event['bet_stake'] = details.betStake;
    }

    if (details.betType) {
      event['bet_type'] = details.betType;
    }

    if (details.couponId) {
      event['coupon_id'] = details.couponId;
    }

    if (details.selectionId) {
      event['selection_id'] = details.selectionId;
    }

    if (details.location) {
      event['location'] = details.location;
    }

    if (details.isFreeBet) {
      event['is_free_bet'] = details.isFreeBet;
    }

    if (details.selections) {
      event['selections'] = details.selections;
    }

    if (details.category) {
      event['category'] = details.category;
    }

    if (details.subCategory) {
      event['sub_category'] = details.subCategory;
    }

    this.createDataLayerEvent(event);
  }

  logFirebaseEvent(event: FirebaseEventType[keyof FirebaseEventType], additionalParams?: object) {
    const fbEvent = window.sendEventToApp;
    if (fbEvent) {
      const command = event.command;
      const eventName = event.name;
      const params = { ...event.params, ...additionalParams };
      if (eventName && params) {
        fbEvent(command, eventName, params);
      }
    }
  }
}
