import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';

import { BetCoupon, BetCouponGroup, BetCouponOdd, CouponType } from 'clientside-coupon';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, of, Subject, timer } from 'rxjs';
import { expand, filter, first, map, startWith, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Router } from '@angular/router';

import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { VirtualsCouponSelectionService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon-selections.service';
import { VirtualsCouponService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { VirtualsCouponStore } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.store';
import { fadeIn } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { CouponGroupingType, CouponOddsModel, GroupingTabsVisibleModel } from 'src/app/shared/models/coupon.model';
import { VirtualsLeagueType } from 'src/app/shared/models/product.model';
import { VirtualsService } from 'src/app/core/services/virtuals.service';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { VirtualsAlertType, VirtualsSlideUpType } from 'src/app/shared/models/virtuals.model';
import { VirtualsSlideUpComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-slide-up/virtuals-slide-up.component';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { VirtualsScheduledFreeBetService } from 'src/app/modules/freebets/services/virtuals-scheduled-free-bet.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { BetslipActions, DataLayerProduct } from 'src/app/shared/models/datalayer.model';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { VirtualsCouponStakeHandlerService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon-stake-handler.service';

@Component({
  selector: 'app-virtuals-coupon',
  templateUrl: './virtuals-coupon.component.html',
  styleUrls: ['./virtuals-coupon.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualsCouponComponent implements OnInit, OnDestroy {
  @Input() slideUpRef?: VirtualsSlideUpComponent;

  @ViewChild('betInProgress') betInProgress: TemplateRef<any>;
  @ViewChildren('couponWrapper') couponWrapperRef: QueryList<ElementRef>;

  currentGroupingsTabSelected: CouponGroupingType;
  overlayRef: OverlayRef;
  overlayTimeout: any;
  readonly buttonType: typeof ButtonType = ButtonType;
  readonly couponType: typeof CouponType = CouponType;
  readonly groupingType: typeof CouponGroupingType = CouponGroupingType;
  readonly virtualsAlertType: typeof VirtualsAlertType = VirtualsAlertType;
  readonly canPostCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly viewInitialized$ = new Subject<boolean>();
  readonly virtualsConfig = this.appConfigService.get('virtuals');
  readonly allowCombinationBets = this.virtualsConfig.scheduledLeague.allowCombinationBets;
  readonly groupingTabsVisible: GroupingTabsVisibleModel = {
    multiple: false,
    split: false,
    singles: false,
    combination: false,
  };
  readonly actionButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '15px',
    height: '44px',
    borderRadius: '50px',
  };
  readonly showColorBackgroundStartTime = 20000;
  readonly showFlashingBackgroundStartTime = 10000;
  readonly freeBetProductType: typeof FreeBetProductType = FreeBetProductType;
  readonly expiredOdds$ = new BehaviorSubject<BetCouponOdd[]>([]);
  readonly expiredWeeks$ = this.expiredOdds$.asObservable().pipe(map(odds => [...new Set(odds.map(odd => odd.TournamentId))]));
  readonly nextEventCategoryId$ = new BehaviorSubject<number>(undefined);
  readonly nextEventExpiresDate$ = new BehaviorSubject<Date>(undefined);
  readonly minTotalStakeErrorMessage$ = new BehaviorSubject<string>(undefined);
  readonly maxTotalStakeErrorMessage$ = new BehaviorSubject<string>(undefined);
  private readonly nextEventExpiresInSubject = new BehaviorSubject<number>(0);
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  nextEventExpiresCountDown$ = this.nextEventExpiresInSubject.asObservable().pipe(
    switchMap(timeUntilExpire =>
      timeUntilExpire
        ? timer(timeUntilExpire % 1000, 1000).pipe(
            map(tick => timeUntilExpire - (timeUntilExpire % 1000) - tick * 1000),
            startWith(timeUntilExpire),
            takeWhile(time => time >= 0)
          )
        : of(0)
    )
  );

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accountService: AccountService,
    readonly applicationService: ApplicationService,
    readonly virtualsCouponQuery: VirtualsCouponQuery,
    readonly virtualsQuery: VirtualsQuery,
    private readonly virtualsCouponService: VirtualsCouponService,
    private readonly virtualsCouponStakeService: VirtualsCouponStakeHandlerService,
    private readonly virtualsCouponStore: VirtualsCouponStore,
    private readonly notificationService: NotificationService,
    private readonly appConfigService: AppConfigService,
    private readonly couponSelectionService: VirtualsCouponSelectionService,
    private readonly overlay: Overlay,
    private readonly vcr: ViewContainerRef,
    private readonly router: Router,
    private readonly location: Location,
    private readonly virtualsService: VirtualsService,
    private readonly virtualsStore: VirtualsStore,
    private readonly dataLayerService: DataLayerService,
    private readonly freebetsService: VirtualsScheduledFreeBetService,
    private readonly currencyFormat: CurrencyFormatPipe
  ) {}

  ngOnInit(): void {
    if (this.virtualsCouponQuery.couponInitialized) {
      this.removeExpiredOdds({ showNotification: true });
    }

    this.scrollToBottom();
    this.accountQuery.isAuthenticated && this.addEventToDataLayer(BetslipActions.BetslipOpen);

    this.virtualsCouponQuery.couponData && this.initFreeBet();

    this.virtualsCouponQuery.couponData$
      .pipe(
        filter(coupon => !!coupon),
        tap(this.parseSelections),
        tap(this.handleTabs),
        switchMap(coupon =>
          of(coupon).pipe(
            expand(c => {
              const now = new Date();
              const expiredOdds = (c && c.Odds && c.Odds.filter(odd => new Date(odd.EventDate) < now)) || [];
              const notExpiredOdds = (c && c.Odds && c.Odds.filter(odd => !expiredOdds.includes(odd))) || [];
              this.expiredOdds$.next(expiredOdds);
              if (notExpiredOdds.length === 0) {
                return EMPTY;
              }

              const nextOddToExpire = notExpiredOdds.sort((a, b) => (new Date(a.EventDate) > new Date(b.EventDate) ? 1 : -1))[0];
              const timeUntilNextExpire = +new Date(nextOddToExpire.EventDate) - +now; // converting to numbers makes TypeScript happy
              this.nextEventExpiresInSubject.next(timeUntilNextExpire);
              this.nextEventExpiresDate$.next(nextOddToExpire.EventDate);

              if (nextOddToExpire.VirtualsCategoryId !== this.nextEventCategoryId$?.getValue()) {
                // Get timings for the next expiring event category, to populate the countdown pie
                this.nextEventCategoryId$.next(nextOddToExpire.VirtualsCategoryId);
                this.virtualsService
                  .getCategoryEventsTimings([nextOddToExpire.VirtualsCategoryId])
                  .pipe(takeUntil(this.destroy$))
                  .subscribe();
              }

              return timer(timeUntilNextExpire).pipe(map(() => c));
            })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.currentGroupingsTabSelected = this.virtualsCouponQuery.groupingsTabSelected;

    this.virtualsCouponQuery.groupingsTabSelected$.pipe(takeUntil(this.destroy$)).subscribe(groupingType => {
      if (groupingType !== undefined && groupingType !== this.currentGroupingsTabSelected) {
        this.currentGroupingsTabSelected = groupingType;
        this.updateGroupingsTab(groupingType);
      }
    });

    let prevCouponSelectionIds = 0;
    this.virtualsCouponQuery.couponSelectionIds$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (prevCouponSelectionIds !== state.length) {
        prevCouponSelectionIds = state.length;

        if (
          this.isGroupingButtonVisible(CouponGroupingType.Multiple) &&
          this.virtualsCouponQuery.groupingsTabSelected === CouponGroupingType.Split
        ) {
          this.virtualsCouponStore.updateGroupingTab(CouponGroupingType.Multiple);
        } else if (
          this.isGroupingButtonVisible(CouponGroupingType.Split) &&
          this.virtualsCouponQuery.groupingsTabSelected === CouponGroupingType.Multiple
        ) {
          this.virtualsCouponStore.updateGroupingTab(CouponGroupingType.Split);
        }
      }
    });

    this.virtualsCouponQuery.minTotalStake$.pipe(takeUntil(this.destroy$)).subscribe(minTotalStake => {
      const message = $localize`Min. total stake is`;
      const value = this.currencyFormat.transform(minTotalStake, Number.isInteger(minTotalStake) ? '1.0-0' : '1.2-2');
      this.minTotalStakeErrorMessage$.next(`${message} ${value}`);
    });

    this.virtualsCouponQuery.maxTotalStake$.pipe(takeUntil(this.destroy$)).subscribe(maxTotalStake => {
      const message = $localize`Max. total stake is`;
      const value = this.currencyFormat.transform(maxTotalStake, Number.isInteger(maxTotalStake) ? '1.0-0' : '1.2-2');
      this.maxTotalStakeErrorMessage$.next(`${message} ${value}`);
    });

    setTimeout(() => {
      this.viewInitialized$.next(true);
    });
  }

  scrollToBottom(): void {
    const initializationSub = combineLatest([this.viewInitialized$, this.virtualsQuery.showingCouponSlideUp$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([viewInitialized, inSlideUp]) => {
        if (viewInitialized) {
          if (inSlideUp) {
            // need to slide both the coupon-wrapper and the virtuals-slide-up component
            // to cater for both mobile and desktop views
            this.couponWrapperRef?.first?.nativeElement?.scrollTo(0, this.couponWrapperRef.first.nativeElement.scrollHeight);
            this.slideUpRef?.scrollToBottom();
          } else {
            window.scrollTo(0, window.document.body.scrollHeight);
          }
          initializationSub.unsubscribe();
        }
      });
  }

  removeExpiredOdds({ showNotification = false }: { showNotification?: boolean } = {}): void {
    const removedOddsCount = this.virtualsCouponService.removeExpiredOdds();
    if (removedOddsCount) {
      const message = $localize`${removedOddsCount} selections got expired. We removed them from the betslip for you.`;
      showNotification && this.notificationService.showWarningMessage(message, 5000, false);
    }
  }

  removeOddsForWeek(tournamentId: number): void {
    const odds = this.expiredOdds$.value.filter(odd => odd.TournamentId === tournamentId).map(odd => odd.SelectionId);
    this.virtualsCouponService.removeOdds(odds);
  }

  continueBetting(): void {
    this.virtualsQuery.showingCouponSlideUp$
      .pipe(first())
      .subscribe(inSlideUp =>
        inSlideUp ? this.virtualsStore.closeSlideUp(VirtualsSlideUpType.Coupon) : this.router.navigate(['/virtual/league'])
      );
  }

  goBack(): void {
    this.virtualsQuery.showingCouponSlideUp$
      .pipe(first())
      .subscribe(inSlideUp => (inSlideUp ? this.virtualsStore.closeSlideUp(VirtualsSlideUpType.Coupon) : this.location.back()));
  }

  getCouponOdds(): CouponOddsModel[] {
    if (this.virtualsCouponQuery.couponData === undefined) {
      return undefined;
    }

    const couponOdds: CouponOddsModel[] = [];
    this.virtualsCouponQuery.couponData.Odds.forEach(odd => {
      couponOdds.push({
        isLive: odd.EventCategory.toLowerCase() === 'l',
        matchId: odd.MatchId,
        marketId: odd.MarketId,
        selectionId: odd.SelectionId,
        selectionValue: odd.OddValue,
      });
    });

    return couponOdds;
  }

  updateGroupingsTab(groupingType: CouponGroupingType): void {
    if (!this.virtualsCouponQuery.couponData) {
      return;
    }

    const groupUpdates: BetCouponGroup[] = [];
    this.virtualsCouponQuery.couponData.AllGroupings.forEach(group => {
      if (!group.Selected) {
        return;
      }

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = false;

      groupUpdates.push(newGrouping);
    });

    if (groupingType === CouponGroupingType.Multiple || groupingType === CouponGroupingType.Split) {
      const lastGroup = this.virtualsCouponQuery.couponData.AllGroupings[this.virtualsCouponQuery.couponData.AllGroupings.length - 1];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = lastGroup.Grouping;
      newGrouping.Combinations = lastGroup.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Singles) {
      const singlesGrouping = this.virtualsCouponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = singlesGrouping.Grouping;
      newGrouping.Combinations = singlesGrouping.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Combination) {
      const group = this.virtualsCouponQuery.couponData.AllGroupings[this.virtualsCouponQuery.couponData.AllGroupings.length - 2];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    }

    if (groupUpdates.length > 0) {
      this.updateGroupings(groupUpdates);
    }
  }

  updateGroupings(groupings: BetCouponGroup[]): void {
    this.virtualsCouponService.updateGroupings(groupings);
  }

  isGroupingButtonVisible(groupingButtonType: CouponGroupingType): boolean {
    if (this.virtualsCouponQuery.couponData) {
      const lastGrouping = this.virtualsCouponQuery.couponData.AllGroupings[this.virtualsCouponQuery.couponData.AllGroupings.length - 1];
      const singlesGrouping = this.virtualsCouponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      if (groupingButtonType === CouponGroupingType.Multiple) {
        if (lastGrouping.Combinations === 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Split) {
        if (lastGrouping !== singlesGrouping && lastGrouping.Combinations > 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Singles) {
        if (singlesGrouping !== null) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Combination) {
        if (this.virtualsCouponQuery.couponData.AllGroupings.filter(g => g.Grouping !== 1).length > 1) {
          return true;
        }
      }
    }
    return false;
  }

  groupingTabClicked(groupingType: CouponGroupingType): void {
    if (groupingType !== this.virtualsCouponQuery.groupingsTabSelected) {
      if (groupingType === CouponGroupingType.Combination) {
        this.virtualsCouponService.enforceSingleCombination = true;
      }
      this.virtualsCouponService.clearAllBankers();
      this.setGroupingTab(groupingType);
    }
  }

  setGroupingTab(groupingType: CouponGroupingType): void {
    if (groupingType !== this.virtualsCouponQuery.groupingsTabSelected) {
      this.virtualsCouponStore.updateGroupingTab(groupingType);
    }
  }

  updateCouponSetting(key: string, value: any): void {
    this.virtualsCouponService.updateCouponSetting(key, value);
  }

  updateStake(stakeValue: number): void {
    this.virtualsCouponStakeService.updateStakeValue(stakeValue);
    this.virtualsCouponStore.updateUI({ stakeChanged: true });
  }

  clearCouponData(): void {
    this.virtualsCouponService.clearCouponData();
  }

  postCoupon(): void {
    if (!this.canPostCoupon$) {
      return;
    }

    if (this.accountQuery.isAuthenticated) {
      this.canPostCoupon$.next(false);
      this.addEventToDataLayer(BetslipActions.BetSubmitted);

      this.overlayTimeout = setTimeout(() => {
        this.showOverlay();
      }, 1000);

      this.virtualsCouponService
        .validateAndPostCoupon()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          success => {
            // Success
            this.canPostCoupon$.next(true);
            this.disposeOverlay();
            if (success) {
              this.continueBetting();
            }
          },
          () => {
            // Error
            this.canPostCoupon$.next(true);
            this.disposeOverlay();
            this.notificationService.showErrorNotification(
              $localize`An error has occurred. Please try again.`,
              $localize`Coupon Not Posted`
            );
          }
        );
    } else {
      if (this.virtualsConfig?.showBetslipLoginDialog) {
        this.applicationService.loginRedirect();
      } else {
        this.notificationService.showInfoNotification(
          $localize`If you wish to confirm your bet, please log into the site`,
          $localize`Login Required`
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.disposeOverlay();

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private showOverlay(): void {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'overlay',
      panelClass: 'bet-loading',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const betInProgress = new TemplatePortal(this.betInProgress, this.vcr);
    this.overlayRef.attach(betInProgress);
  }

  private disposeOverlay(): void {
    if (this.overlayTimeout) {
      clearTimeout(this.overlayTimeout);
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }

  private readonly parseSelections = (coupon: BetCoupon): void => {
    setTimeout(() => {
      // This had to be done so that the parsing is done in a separate digest cycle
      // if the coupon is edited within the betslip screen
      this.couponSelectionService.parseSelections(coupon.Odds, VirtualsLeagueType.Scheduled);
    });
  };

  private readonly handleTabs = (coupon: BetCoupon): void => {
    if (coupon.CouponType === CouponType.Single || coupon.AllGroupings === null) {
      return;
    }

    if (
      this.virtualsCouponQuery.groupingsTabSelected === undefined ||
      this.currentGroupingsTabSelected !== this.virtualsCouponQuery.groupingsTabSelected
    ) {
      // determine which combination tab to show
      const lastGrouping = coupon.AllGroupings[coupon.AllGroupings.length - 1];
      const singlesGrouping = coupon.AllGroupings.find(g => g.Grouping === 1);

      if (coupon.Groupings.length === 1 && lastGrouping.Selected) {
        if (lastGrouping.Combinations === 1) {
          this.setGroupingTab(CouponGroupingType.Multiple);
        } else if (lastGrouping === singlesGrouping) {
          this.setGroupingTab(CouponGroupingType.Singles);
        } else {
          this.setGroupingTab(CouponGroupingType.Split);
        }
      } else if (coupon.Groupings.length === 1 && singlesGrouping && singlesGrouping.Selected) {
        this.setGroupingTab(CouponGroupingType.Singles);
      } else {
        this.setGroupingTab(CouponGroupingType.Combination);
      }
    }

    // check group tabs visibility
    this.groupingTabsVisible.multiple = this.isGroupingButtonVisible(CouponGroupingType.Multiple);
    this.groupingTabsVisible.split = this.isGroupingButtonVisible(CouponGroupingType.Split);
    this.groupingTabsVisible.singles = this.isGroupingButtonVisible(CouponGroupingType.Singles);
    this.groupingTabsVisible.combination = this.isGroupingButtonVisible(CouponGroupingType.Combination);
  };

  private addEventToDataLayer(event: BetslipActions): void {
    const numberOfSelections = this.virtualsCouponQuery.couponData?.Odds?.length ?? 0;

    this.dataLayerService.addBetslipEventToDataLayer({
      event: event,
      userId: this.accountQuery.userData?.id,
      product: DataLayerProduct.VirtualsScheduled,
      isFreeBet: !!this.virtualsCouponQuery.couponData?.BetDetails?.FreeBetDetails,
      selections: numberOfSelections,
    });
  }

  private initFreeBet(): void {
    forkJoin([
      this.freebetsService.initialiseFreebetsConfig(),
      this.freebetsService.initialiseFreebetsForBetslip(),
      this.freebetsService.initialiseFreebetsBetslipContent(),
      this.freebetsService.getUserVouchers(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}
