<app-header *ngIf="showHeader" [headerPinToTop]="false"></app-header>

<app-loading-bar [show]="(virtualsQuery.lobbyContent$ | async) === undefined" [transparent]="true" [delay]="1000">
</app-loading-bar>

<virtuals-jackpot-banner *ngIf="displayJackpotBanner$ | async" [jackpotBanner]="jackpotBanner$ | async"></virtuals-jackpot-banner>

<div class="lobby-container">
  <div class="content-wrapper">
    <ng-container *ngIf="virtualsQuery.lobbyContent$ | async as lobbyContent">
      <h1 *ngIf="showPageTitle && lobbyContent.pageTitle" class="header">{{ lobbyContent.pageTitle }}</h1>

      <div class="section-container">
        <virtuals-campaign-banner
          *ngIf="virtualsQuery.lobbyCampaignBannerVisibility$ | async"
          [campaign]="virtualsQuery.lobbyCampaignBanner$ | async"
        ></virtuals-campaign-banner>

        <div *ngFor="let section of lobbyContent.sections" class="section">
          <div class="section-header">
            <div class="title-container">
              <div class="jackpot-tooltip" *ngIf="section.jackpot && lobbyContent.infoBubbleContent">
                <virtuals-jackpot-tooltip
                  [tooltipContent]="lobbyContent.infoBubbleContent"
                ></virtuals-jackpot-tooltip>
              </div>
              <div *ngIf="section.title" class="title" [class.is-new]="section.isNew">
                <h1>{{ section.title }}</h1>
                <span *ngIf="section.isNew" class="new-badge" i18n>NEW</span>
              </div>
              <h1 *ngIf="section.subTitle" class="subtitle">{{ section.subTitle }}</h1>
            </div>
          </div>

          <div class="subsection-container">
            <div *ngFor="let subSection of section.subSections" class="subsection">
              <h1 *ngIf="subSection.title" class="title">{{ subSection.title }}</h1>
              <div class="game-container" [style.--games-per-row]="subSection.gamesPerRow || 2">
                <div *ngFor="let game of subSection.games" class="game"
                  [ngClass]="['game-' + game.code, 'type-' + game.gameType]"
                  [class.has-ribbon]="game.isNew"
                  [attr.data-testid]="'game-' + (game.code | kebabCase)" (click)="openGame(game)">
                  <div class="disabled-overlay" *ngIf="isGameDisabled(game)"></div>
                  <div class="logo" [ngClass]="{ 'disabled-image': isGameDisabled(game) }">
                    <img *ngIf="game.logoUrl" [src]="game.logoUrl" [alt]="game.code" />
                  </div>
                  <div class="extra-fields" *ngIf="game.showCountdown || game.showPlayButton">
                    <app-pie-countdown *ngIf="virtualsQuery.categoryEventTiming$(game.providerId) | async as countdown"
                      class="countdown" [targetDate]="countdown.nextEventDate" [pieFullDuration]="countdown.eventInterval"
                      [pieSize]="7" [textSize]="12" [textLineHeight]="10" [borderSpacing]="1" [borderThickness]="1"
                      [showCountdownText]="true" [showBackground]="false" [color]="'rgba(255, 255, 255, 0.56)'"
                      [textColor]="'rgba(255, 255, 255, 0.56)'" [liveBadgeWidth]="42.5" [outerRingSize]="11"
                      [outerRingBorderThickness]="1" [outerRingMarginLeft]="5"
                      [innerCircleMaxSize]="7" [playoutTimeMs]="section.playoutDisplayDuration" (timeElapsed)="refreshEventTiming(game.gameType)" [showLiveFlag]="true">
                    </app-pie-countdown>
                    <!-- Functionality for button text to be done with Strapi implementation -->
                    <span *ngIf="game.showPlayButton" class="play-button" i18n>{{ game.playButtonText ? game.playButtonText : 'Play'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="cta-container" *ngIf="section.ctaButtonText && section.ctaClickUrl">
            <span *ngIf="section.ctaLabel" class="label">{{ section.ctaLabel }}</span>
            <span class="button" data-testid="cta-button" (click)="ctaClick(section.ctaClickUrl)">
              {{ section.ctaButtonText }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="(virtualsQuery.lobbyInfoSections$ | async)?.length" class="lobby-info-sections"
      data-testid="virtuals-lobby-info-section-container">
      <virtuals-info-section *ngFor="let section of (virtualsQuery.lobbyInfoSections$ | async)"
        [section]="section"></virtuals-info-section>
    </div>
  </div>
</div>
