import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil, tap, timeout } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/language.service';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import {
  VirtualsBetSuccessDialogModel,
  VirtualsJackpot,
  VirtualsJackpotBanner,
  VirtualsLatestJackpotWinnersInfo,
  VirtualsLobbyInfoData,
} from 'src/app/shared/models/virtuals.model';
import { mapToCamelCase } from 'src/app/shared/utils/map-to-camel-case';
import { BreadcrumbNavigation } from 'src/app/shared/models/breadcrumb-navigation';
import { UserType } from 'src/app/shared/models/account.model';
import { format } from 'date-fns';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { ABTestService } from 'src/app/core/services/ab-test.service';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualsService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly virtualsStore: VirtualsStore,
    private readonly virtualsQuery: VirtualsQuery,
    private readonly appConfig: AppConfigService,
    private readonly accountQuery: AccountQuery,
    private readonly dataLayerService: DataLayerService,
    private readonly cookieService: CookieService,
    private readonly abTestService: ABTestService
  ) {}

  get isVirtualsEnabled(): boolean {
    return this.appConfig.get('virtuals')?.enabled;
  }

  get isScheduledLeagueEnabled(): boolean {
    return this.appConfig.get('virtuals')?.scheduledLeague?.enabled;
  }

  get isInstantLeagueEnabled(): boolean {
    return this.appConfig.get('virtuals')?.instantLeague?.enabled;
  }

  getVirtualsGameFrameUrl(clientType: string = 'web'): Observable<any> {
    return this.apiService
      .get(APIType.Platform, `/api/GamingVendors/GlobalBet/GameFrameUrl?globalBetClientType=${clientType}`)
      .pipe(takeUntil(this.destroy$));
  }

  getVirtualsLatestJackpotWinnerSection(): Observable<VirtualsLatestJackpotWinnersInfo> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const language: string = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService
      .get(APIType.CMS, `/Virtuals/GetVirtualsLatestJackpotWinnerSection?language=${language}`, apiSettings)
      .pipe(takeUntil(this.destroy$));
  }

  getGroupEntityId(userId: number): Observable<any> {
    return this.apiService.get(APIType.Platform, `/api/GamingVendors/GoldenRace/GroupEntityID?userID=${userId}`).pipe(
      map(response => {
        if (response && response.Result) {
          this.virtualsStore.updateGroupEntityId(response.Result);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  getCategoryEventsTimings(categoryIds: (number | string)[]): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    if (!categoryIds?.length) {
      return of({});
    }

    const threshold: number = this.appConfig.get('virtuals')?.eventsTimingsThreshold || 0;
    return this.apiService.get(APIType.VirtualsFeeds, `v1/categories/${categoryIds?.join(',')}/timings/${threshold}`, apiSettings).pipe(
      map(mapToCamelCase),
      map(response => {
        if (response?.categoryEventsTimings) {
          this.virtualsStore.updateCategoryEventsTimings(response.categoryEventsTimings);
        } else {
          this.virtualsStore.removeCategoryEventsTimings(categoryIds);
        }
      }),
      catchError(error => {
        this.virtualsStore.removeCategoryEventsTimings(categoryIds);
        return throwError(error);
      }),
      takeUntil(this.destroy$)
    );
  }

  getTournamentsEventsTimings(categoryId: string): Observable<any> {
    if (!categoryId) {
      return of({});
    }

    return this.apiService.get(APIType.BFFGateway, `api/virtuals/v1/football/rounds/${categoryId}?limit=3`).pipe(
      map(response => {
        if (response.data) {
          const upcomingRounds = response.data.filter(round => new Date(round.date).getTime() > Date.now());

          if (upcomingRounds.length >= 2) {
            const [firstRound, secondRound] = upcomingRounds;
            const eventInterval = new Date(secondRound.date).getTime() - new Date(firstRound.date).getTime();

            this.virtualsStore.updateCategoryEventsTimings({
              [categoryId]: {
                eventInterval,
                nextEventDate: firstRound.date,
              },
            });
          }
        } else {
          this.virtualsStore.removeCategoryEventsTimings([categoryId]);
        }
      }),
      catchError(error => {
        this.virtualsStore.removeCategoryEventsTimings([categoryId]);
        return throwError(error);
      }),
      takeUntil(this.destroy$)
    );
  }

  getJackpots(): Observable<VirtualsJackpot[]> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.KMJackpots, 'v1/jackpots', apiSettings).pipe(
      map(mapToCamelCase),
      map(response => {
        const jackpots: VirtualsJackpot[] = [];
        if (response?.jackpots) {
          response.jackpots.forEach(jackpot => {
            jackpots.push({
              id: jackpot.jackpotId,
              name: jackpot.jackpotName,
              value: jackpot.jackpotValue,
              dropFrequency: jackpot.jackpotDropFrequencyInSeconds,
              updateDate: jackpot.updateDate,
              tippingPoint: jackpot.tippingPoint,
              nextDrop: jackpot.nextDrop,
            });
          });
          this.virtualsStore.updateJackpots(jackpots);
        }
        return jackpots;
      }),
      takeUntil(this.destroy$)
    );
  }

  getLobbyContent(includeDisabled: boolean = false): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    // Retrieve the data only once
    if (this.virtualsQuery.lobbyContent) {
      return of(undefined);
    }

    const language = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService.get(APIType.CMS, `/Virtuals/GetLobby?language=${language}&includeDisabled=${includeDisabled}`, apiSettings).pipe(
      catchError(err => {
        this.setDefaultLobbyContent();
        return throwError(err);
      }),
      map(lobbyContent => {
        if (!lobbyContent || Object.keys(lobbyContent).length === 0) {
          this.setDefaultLobbyContent();
        } else {
          // turboBundliga ABTest
          const bundligaTestConfig = this.appConfig.get('abTesting')?.activeTests?.find(test => test.id === 'turboBundliga');
          const leagueId = bundligaTestConfig?.data?.leagueId?.toString();
          const turboLeagueId = bundligaTestConfig?.data?.turboLeagueId?.toString();

          if (bundligaTestConfig && leagueId && turboLeagueId) {
            lobbyContent.sections?.forEach(section => {
              section.subSections?.forEach(subSection => {
                subSection.games?.forEach(game => {
                  if (game.providerId === leagueId) {
                    this.abTestService
                      .getValue(bundligaTestConfig.experimentId, bundligaTestConfig.defaultValue)
                      .pipe(takeUntil(this.destroy$))
                      .subscribe(turboBundligaVariant => {
                        if (turboBundligaVariant === 'Variation-1') {
                          game.providerId = turboLeagueId; // Update providerId to turboLeagueId
                        }
                      });
                  }
                });
              });
            });
          }
          this.virtualsStore.updateLobbyContent(lobbyContent);
          // turboBundliga ABTest
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  private setDefaultLobbyContent(): void {
    this.virtualsStore.updateLobbyContent(this.appConfig.get('virtuals').lobbyContentDefaults);
  }

  getLobbyInfoData(): Observable<VirtualsLobbyInfoData> {
    // Retrieve the data only once
    if (this.virtualsQuery.lobbyInfoSections) {
      return of({
        infoSections: this.virtualsQuery.lobbyInfoSections,
        campaignBanner: this.virtualsQuery.lobbyCampaignBanner,
      });
    }

    const params = new URLSearchParams({
      locale: this.languageService.strapiCMSLocale,
      'populate[infoSections][populate]': '*',
      'populate[campaignBanner][populate]': '*',
    });

    const url = `virtuals-lobby-page?${params.toString()}`;
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
      map(({ data }) => ({
        infoSections: data?.attributes?.infoSections,
        campaignBanner: {
          ...data?.attributes?.campaignBanner,
          bannerImage: data?.attributes?.campaignBanner?.bannerImage?.data?.attributes || null,
        },
      })),
      tap(({ infoSections, campaignBanner }) => {
        !!infoSections && this.virtualsStore.updateLobbyInfoSections(infoSections);
        !!campaignBanner && this.virtualsStore.updateLobbyCampaignBanner(campaignBanner);
      }),
      takeUntil(this.destroy$)
    );
  }

  getJackpotBanner(): Observable<VirtualsJackpotBanner> {
    const existingBanner = this.virtualsQuery.jackpotBanner;
    if (existingBanner) {
      return of(existingBanner);
    }

    const params = new URLSearchParams({
      locale: this.languageService.strapiCMSLocale,
      'populate[jackpotBanner][fields][0]': 'bannerMessage',
      'populate[jackpotBanner][fields][1]': 'latestJackpotUrl',
      'populate[jackpotBanner][populate]': 'visibility',
    });

    const url = `virtuals-jackpot?${params.toString()}`;
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
      map(({ data }) => data?.attributes?.jackpotBanner),
      tap(jackpotBanner => {
        if (jackpotBanner) {
          this.virtualsStore.updateJackpotBanner(jackpotBanner);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  getBreadcrumbNavigation(): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    if (this.virtualsQuery.breadcrumbNavigation) {
      return of(undefined);
    }
    const language = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService.get(APIType.CMS, `/Virtuals/GetBreadcrumbNavigation?language=${language}`, apiSettings).pipe(
      timeout(5000),
      catchError(err => {
        this.setDefaultBreadcrumbNavigation();
        return throwError(err);
      }),
      map((breadcrumbData: BreadcrumbNavigation) => {
        if (!breadcrumbData || !Object.values(breadcrumbData).some(propertyValue => propertyValue)) {
          this.setDefaultBreadcrumbNavigation();
        } else {
          this.virtualsStore.updateBreadcrumbNavigation(breadcrumbData);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  getSuccessBetDialogContentStrapi(): Observable<VirtualsBetSuccessDialogModel> {
    if (this.virtualsQuery.betSuccessDialogContent) {
      return of(this.virtualsQuery.betSuccessDialogContent);
    }

    const params = new URLSearchParams({
      locale: this.languageService.strapiCMSLocale,
      'populate[labels][populate]': '*',
      'populate[settings][populate]': '*',
    });

    const url = `virtuals-tournaments-page?${params.toString()}`;
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.StrapiCms, url, apiSettings).pipe(
      map(resp => {
        if (!resp || !resp.data) {
          return null;
        }

        const pageData = resp.data.attributes;

        const betSuccessDialogContent: VirtualsBetSuccessDialogModel = {
          labels: {
            betslipDialogSuccessCTA: pageData.labels.betslipDialogSuccessCTA,
            betslipDialogSuccessContent: pageData.labels.betslipDialogSuccessContent,
            betslipDialogSuccessContentTitle: pageData.labels.betslipDialogSuccessContentTitle,
            betslipDialogSuccessTitle: pageData.labels.betslipDialogSuccessTitle,
            betslipDialogSuccessJPSingular: pageData.labels.betslipDialogSuccessJPSingular,
          },
          settings: {
            ticketPriceForKingJP: pageData.settings.ticketPriceForKingJP,
            dukeTicketContribution: pageData.settings.dukeTicketContribution,
          },
        };

        this.virtualsStore.updateSuccessBetDialogContent(betSuccessDialogContent);
        return betSuccessDialogContent;
      }),
      takeUntil(this.destroy$)
    );
  }

  isEndpointDisabledForUser(url: string): boolean {
    if (!this.accountQuery.isAuthenticated) {
      return false;
    }

    const virtualsConfig = this.appConfig.get('virtuals');
    const disabledLeaguesForSubAccountPlayers: any[] = virtualsConfig.disabledLeaguesForSubAccountPlayers;
    const disabledGamesForSubAccountPlayers: any[] = virtualsConfig.goldenRace.disabledGamesForSubAccountPlayers;
    const leagueCategoryId: string = url.substring(url.lastIndexOf('/') + 1);
    const isSubAccount: boolean = this.accountQuery.userData?.userTypeCode === UserType.SubAccountPlayer;

    return (
      isSubAccount &&
      (disabledGamesForSubAccountPlayers.includes(leagueCategoryId) || disabledLeaguesForSubAccountPlayers.includes(leagueCategoryId))
    );
  }

  createDataLayerEvent(eventName: string, gameType: string, gameCode: string): void {
    this.dataLayerService.createDataLayerEvent({
      event: eventName,
      product: gameType,
      userId: this.accountQuery.userData?.id,
      uniqueUserIdentifier: this.setUniqueUserIdentifierCookie(),
      time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      gameCode,
    });
  }

  private setUniqueUserIdentifierCookie() {
    const cookieKey = 'uniqueUserIdentifier';
    const existingCookie = this.cookieService.getCookie(cookieKey);

    if (!existingCookie) {
      const uniqueUserIdentifier = Math.random().toString().substring(7);
      this.cookieService.setCookie(cookieKey, uniqueUserIdentifier);
      return uniqueUserIdentifier;
    } else {
      return existingCookie;
    }
  }

  private setDefaultBreadcrumbNavigation(): void {
    this.virtualsStore.updateBreadcrumbNavigation(this.appConfig.get('virtuals').breadcrumbNavigationDefaults);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
